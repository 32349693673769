import React from 'react'
import { Container, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '../../ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'


const styles = 
 {
    imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    maxHeight: `440px`,
    minHeight: `440px`,
    bg: `omegaLighter`,
  },
}

const FeaturesWithPhotoBlock08 = ({ content: { text, buttons, images } }) => (
  <Container sx={{ textAlign: `center` }}>
    <Box>
      {buttons && (
        <>
          <Divider space={3} />
          <ContentButtons content={buttons} />
        </>
      )}
    </Box>
    {images && (
      <>
        <Divider space={3} />
        <Box sx={styles.imageWrapper}>
          <ContentImages
            content={{ images }}
            loading='eager'
            imagePosition='center'
            imageEffect='fadeInDown'
            sx={styles.image}
          />

        {text && (
          <Box>
            <ContentText content={text}/>
          </Box>
        )}
        </Box>
      </>
    )}
  </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock08)
