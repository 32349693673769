import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { Container } from 'theme-ui'
import Screenshot from '../../Components/FeaturesWithPhoto/Block08'
import Tabs from '@solid-ui-components/Tabs'

import theme from './_theme'
import styles from './_styles'

const WelcomePentahoPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const collection = content['screenshots'].collection;

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Pentaho' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={3} variant='dots' position='bottom' autoplayInterval={15000} autoplay={true} arrows>
        { collection.map((col) => (
          <Screenshot content={col} />
        )) }
        </Tabs>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query innerpageWelcomePentahoBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/welcome", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default WelcomePentahoPage
